import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, desktop, getFontFamily, tablet } from 'services';
import { Counter } from 'components/atoms';
import { ContactForm } from 'components/molecules';
import {
  Box,
  ErrorText,
  Form,
  FormText,
  Hint,
  StyledButton,
  StyledErrorForm,
  StyledInput,
  StyledInputMask,
} from 'components/molecules/ContactForm/ContactForm';

interface IMainFormProps {
  hasDiscountInfo?: boolean;
  discountPrice?: string;
  oldPrice?: string;
  perMonth?: string;
  className?: string;
}

export const MainForm = memo(
  ({
    hasDiscountInfo,
    discountPrice,
    oldPrice,
    perMonth,
    className,
  }: IMainFormProps) => (
    <FormWrapper className={className}>
      {hasDiscountInfo && (
        <DiscountInfoWrapper>
          <ForCourseText>За весь курс</ForCourseText>
          <Prices>
            <DiscountPrice>{discountPrice}</DiscountPrice>
            <OldPrice>{oldPrice}</OldPrice>
          </Prices>
          <MonthPrice>
            {perMonth} <PerMonth>₽/мес.</PerMonth>
          </MonthPrice>
          <ForMonthText>При рассрочке на 12 месяцев</ForMonthText>
        </DiscountInfoWrapper>
      )}
      <CounterStyled />

      <ContactForm
        titleBtn="Забронировать место"
        text="Мы свяжемся в течение дня, бесплатно проконсультируем и поможем с выбором направления."
      />
    </FormWrapper>
  ),
);

const FormWrapper = styled.form`
  position: relative;
  background-color: ${ColorService.MAIN_BLACK};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px 32px;
  border: 1px solid ${ColorService.SILVER};
  margin: 0 -16px 0;

  ${tablet} {
    margin: 0;
    padding: 24px 40px 32px;
  }

  ${desktop} {
    width: 356px;
  }

  ${Box} {
    padding: 0;
  }

  ${Form} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  ${Hint} {
    text-align: center;
  }

  ${FormText} {
    display: none;
    text-align: center;
  }

  ${StyledInput} {
    width: 100%;
  }

  ${StyledInputMask} {
    width: 100%;
  }

  ${StyledButton} {
    width: 100%;
    margin-bottom: 24px;
  }

  ${ErrorText} {
    max-width: 360px;
  }

  ${StyledErrorForm} {
    margin-top: -22px;
    text-align: center;
  }
`;

const CounterStyled = styled(Counter)`
  width: 100%;
  margin-bottom: 24px;
`;

const DiscountInfoWrapper = styled.div`
  display: grid;
  grid-row-gap: 4px;
  grid-template-areas:
    'forCourse'
    'price'
    'monthPrice'
    'forMonth';
  margin-bottom: 24px;

  ${tablet} {
    grid-template-areas:
      'monthPrice price'
      'forMonth forCourse';
    align-items: baseline;
  }

  ${desktop} {
    grid-template-areas:
      'forCourse'
      'price'
      'monthPrice'
      'forMonth';
  }
`;

const Text = styled.p`
  color: ${ColorService.GRAY};
`;

const ForCourseText = styled(Text)`
  grid-area: forCourse;
`;

const ForMonthText = styled(Text)`
  grid-area: forMonth;
`;

const Prices = styled.div`
  grid-area: price;
  display: flex;
  gap: 16px;
  color: ${ColorService.WHITE};
`;

const DiscountPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 24px;
  line-height: 36px;
`;

const OldPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 18px;
  line-height: 36px;
  text-decoration-line: line-through;
`;

const MonthPrice = styled.p`
  grid-area: monthPrice;
  font-family: ${getFontFamily('bold')};
  font-size: 42px;
  line-height: 54px;
  color: ${ColorService.YELLOW};
`;

const PerMonth = styled.span`
  font-family: ${getFontFamily('bold')};
  font-size: 32px;
  line-height: 54px;
`;
